import { type FC } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Dropdown, Navbar } from 'flowbite-react';
import { authProvider } from 'providers/authProvider';
import PrimaryLogo from 'components/atoms/PrimaryLogo';

const GsHeader: FC = () => {
  const logout = () => {
    authProvider.signOut();
    window.location.href = '/users/sign_in';
  };

  return (
    <Navbar fluid rounded className="border-b">
      <Navbar.Brand as={Link} to={'/analytics'}>
        <div className="-mt-1">
          <PrimaryLogo />
        </div>
        <span className="ml-5 text-sm text-gray-600">アナリティクス一覧</span>
      </Navbar.Brand>
      <div className="flex md:order-2">
        <Dropdown
          arrowIcon={false}
          inline
          label={<Avatar alt="User settings" rounded />}
        >
          <Dropdown.Item onClick={logout}>ログアウト</Dropdown.Item>
        </Dropdown>
      </div>
    </Navbar>
  );
};

export default GsHeader;
